<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="系统编号">
                <a-input v-model.trim="queryParam.serial_num" placeholder="根据系统编号查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="合同编号">
                <a-input v-model.trim="queryParam.contract_no" placeholder="根据合同编号查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="合作方名称">
                <a-input v-model.trim="queryParam.customer_name" placeholder="根据合作方名称查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="归档编号">
                <a-input v-model.trim="queryParam.filing_no" placeholder="根据归档编号查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="合同名称">
                <a-input v-model.trim="queryParam.contract_name" placeholder="根据合同名称查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务部门">
                <a-select
                  v-model="queryParam.department"
                  allowClear
                  placeholder="请选择业务部门"
                  @change="loadData"
                >
                  <a-select-option v-for="op in departmentOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务类型">
                <a-select
                  v-model="queryParam.lease_type"
                  allowClear
                  placeholder="请选择业务类型"
                  @change="loadData"
                >
                  <a-select-option v-for="op in leaseTypeOps" :key="op.id">{{ op.contract_name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="合同类型">
                <a-select
                  v-model="queryParam.contract_type"
                  allowClear
                  placeholder="请选择合同类型"
                  @change="loadData"
                >
                  <a-select-option v-for="op in contractTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="付款类型">
                <a-select
                  v-model="queryParam.pay_type"
                  allowClear
                  placeholder="请选择付款类型"
                  @change="loadData"
                >
                  <a-select-option v-for="op in payTypeOps" :key="op.id">{{ op.business_name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="合同状态">
                <a-select
                  v-model="queryParam.contract_status_list"
                  allowClear
                  placeholder="请选择合同类型"
                  mode="multiple"
                >
                  <a-select-option v-for="op in contractStatusOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="税务类型">
                <a-select
                  v-model="queryParam.tax_type"
                  allowClear
                  placeholder="请选择税务类型"
                  @change="loadData"
                >
                  <a-select-option v-for="op in taxTypeOpsNone" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="合同签订时间">
                <a-range-picker @change="signDateChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="合同有效时间">
                <a-range-picker @change="validDateChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="提醒邮件发送状态">
                <a-select
                  v-model="queryParam.send_email_flag"
                  allowClear
                  @change="loadData"
                >
                  <a-select-option :key="0">未发送</a-select-option>
                  <a-select-option :key="1">发送成功</a-select-option>
                  <a-select-option :key="2">发送失败</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="queryList" v-perms="'MMS_CONTRACT_QUERY'">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate" v-perms="'MMS_CONTRACT_ADD'">新增</a-button>
        <a-button type="danger" icon="delete" @click="handleDelete" :disabled="!selectedRowKeys.length" v-perms="'MMS_CONTRACT_REMOVE'">删除</a-button>
        <a-button type="primary" icon="export" @click="exportSheet" v-perms="'MMS_CONTRACT_EXPORT'">导出合同汇总表</a-button>
      </div>

      <a-table
        size="small"
        row-key="id"
        bordered
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleChangeTable"
        :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="handleUpdate(record)" v-perms="'MMS_CONTRACT_MODIFY'">编辑</a>
          <a-divider type="vertical" />
          <a-upload
            :beforeUpload="beforeUpload"
            :customRequest="e => handleUpload(e, record)"
            :showUploadList="false"
            v-perms="'MMS_CONTRACT_UPLOAD'"
          >
            <a>上传文件</a>
          </a-upload>
          <a-divider type="vertical" v-if="record.contract_file_list"/>
          <a @click="handleDeleteFile(record)" v-if="record.contract_file_list" v-perms="'MMS_CONTRACT_MODIFY'">删除文件</a>
          <!-- 签署人是当前登录用户才显示操作按钮 -->
          <a-divider type="vertical" v-if="currentUser.id === record.signor_id"/>
          <a v-if="currentUser.id === record.signor_id" @click="handleUpdate(record, 'modifyRemark')">编辑备注</a>
          <a-divider type="vertical" v-if="currentUser.department === 5"/>
          <a v-if="currentUser.department === 5" @click="handleUpdate(record, 'modifyTaxType')">编辑税务</a>
        </template>
        <template slot="contractFileSlot" slot-scope="text">
          <div v-for="op in text" :key="op">
            <a :href="op" target="downloadFile" v-if="op">
              <a-icon type="file-image" />
            </a>
          </div>
        </template>
        <template slot="emailFlagSolt" slot-scope="text">
          <a-tag :color="text===1?'green':text===2?'red':'blue'">
            {{ text===1?'发送成功':text===2?'发送失败':'未发送' }}
          </a-tag>
        </template>
      </a-table>

      <a-modal
        :visible="visible"
        :confirmLoading="loading"
        :width="1000"
        :maskClosable="false"
        :title="title"
        @ok="handleSubmit"
        @cancel="handleCancel"
      >
        <a-form-model
          layout="vertical"
          ref="form"
          :model="form"
          :rules="rules"
        >
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="合同编号" prop="contract_no">
                <a-input v-model.trim="form.contract_no" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'" />
              </a-form-model-item>
            </a-col>
            <a-col :span="18">
              <a-form-model-item label="合作方名称" prop="customer_id">
                <a-select
                  showSearch
                  allowClear
                  label-in-value
                  :value="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in buyerOps" :key="op.value">
                    {{ op.type === 1 ? '客户' : '供应商' }}-{{ op.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="18">
              <a-form-model-item label="第三方合作方" prop="third_customer_id">
                <a-select
                  showSearch
                  allowClear
                  label-in-value
                  :value="thirdCustomerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeThirdCustomerInfo"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in buyerOps" :key="op.value">
                    {{ op.type === 1 ? '客户' : '供应商' }}-{{ op.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="合同类型" prop="contract_type">
                <a-select
                  v-model="form.contract_type"
                  allowClear
                  placeholder="请选择合同类型"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="op in contractTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="业务类型">
                <a-select
                  v-model="form.lease_type"
                  allowClear
                  placeholder="请选择业务类型"
                  :disabled="form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="op in leaseTypeOps" :key="op.id">{{ op.contract_name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="付款类型" prop="pay_type">
                <a-select
                  v-model="form.pay_type"
                  allowClear
                  placeholder="请选择付款类型"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="op in payTypeOps" :key="op.id">{{ op.business_name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="业务部门" prop="department_ids">
                <a-select
                  v-model="form.department_ids"
                  allowClear
                  placeholder="请选择签署部门"
                  mode="multiple"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="op in departmentOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6" v-if="form.department_ids&&form.department_ids.includes(6)">
              <!-- 选择了堆场部门-在选择堆场归属 -->
              <a-form-model-item label="堆场">
                <a-select
                  v-model="form.yard_id"
                  allowClear
                  placeholder="选择堆场"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="item in yardList" :key="item.value">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="签署人" prop="signor_id">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择签署人"
                  label-in-value
                  optionFilterProp="label"
                  :filter-option="filterOption"
                  :value="signorInfo"
                  @change="changeSignorInfo"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="op in userOps" :key="op.value" :label="op.name">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="签订日期" prop="sign_date">
                <a-date-picker v-model="form.sign_date" style="width: 100%" format="YYYY-MM-DD" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="签署部门" prop="signor_department">
                <a-select
                  v-model="form.signor_department"
                  allowClear
                  placeholder="请选择签署部门"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="op in signorDepartmentOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="合同起始日期" prop="contract_start_date">
                <a-date-picker v-model="form.contract_start_date" style="width: 100%" format="YYYY-MM-DD" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="合同结束日期" prop="contract_end_date">
                <a-date-picker v-model="form.contract_end_date" style="width: 100%" format="YYYY-MM-DD" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="合同顺延截止日期" prop="contract_extension_deadline">
                <a-input v-model="form.contract_extension_deadline" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="合同终止日期" prop="contract_termination_date">
                <a-date-picker v-model="form.contract_termination_date" style="width: 100%" format="YYYY/MM/DD" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="归档编号" prop="filing_no">
                <a-input v-model.trim="form.filing_no" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="合同名称" prop="contract_name">
                <a-input v-model.trim="form.contract_name" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="结算方式" prop="settlement_type">
                <a-select
                  v-model="form.settlement_type"
                  allowClear
                  placeholder="请选择保证金/押金"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="op in settlementTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="合同信用期（天数）" prop="credit_period">
                <a-input v-model="form.credit_period" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="合同标的额" prop="contract_amount">
                <a-input v-model="form.contract_amount" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="保证金/押金" prop="deposit">
                <a-input v-model="form.deposit" :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="合同状态" prop="contract_status">
                <a-select
                  v-model="form.contract_status"
                  allowClear
                  placeholder="请选择合同状态"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="op in contractStatusOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item mode="multiple" label="税务类型" prop="tax_type">
                <a-select
                  mode="multiple"
                  v-model="form.tax_type"
                  allowClear
                  placeholder="请选择税务类型"
                  :disabled="form.flag === 'modifyRemark'"
                >
                  <a-select-option v-for="op in taxTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="合同是否无期限" prop="is_no_term">
                <a-select
                  v-model="form.is_no_term"
                  allowClear
                  placeholder="请选择合同是否无期限"
                  :disabled="form.flag === 'modifyRemark' || form.flag === 'modifyTaxType'"
                >
                  <a-select-option v-for="op in noTermList" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model.trim="form.remark" :disabled="form.flag === 'modifyTaxType'"/>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import {
  initLeaseInfo,
  pageLeaseInfo,
  saveOrUpdateLeaseInfo,
  deleteLeaseInfo,
  exportLeaseInfo,
  uploadFileByLeaseInfo,
  modifyLeaseInfoRemark,
  modifyLeaseInfoTaxType,
  deleteContractFile
} from '@/api/mms'
import { getSysUserMetaOption } from '@/api/system'
import { getCustomer, downloadExcel } from '@/api/common'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { getFirstLetter } from '@/utils/util'

export default {
  name: 'Contract',
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      queryParam: {},
      currentUser: this.$store.getters.userInfo,
      leaseTypeOps: [], // 业务类型
      contractTypeOps: [], // 合同类型
      payTypeOps: [], // 付款类型
      contractStatusOps: [], // 合同状态
      taxTypeOps: [], // 税务类型
      taxTypeOpsNone: [], // 税务类型
      buyerOps: [], // 客户信息
      userOps: [], // 签署人信息
      // 业务部门
      departmentOps: [],
      // 签署部门
      signorDepartmentOps: [
        { value: '总经办', name: '总经办' },
        { value: '财商部', name: '财商部' },
        { value: '综合部', name: '综合部' },
        { value: '仓储分公司', name: '仓储分公司' },
        { value: '国贸分公司', name: '国贸分公司' },
        { value: '浦东分公司', name: '浦东分公司' }
      ],
      settlementTypeOps: [
        { value: '月结', name: '月结' },
        { value: '付款买单', name: '付款买单' },
        { value: '按约定', name: '按约定' }
      ],
      // 是否有保证金/押金
      depositOps: [
        { value: 1, name: '有' },
        { value: 2, name: '无' }
      ],
      // 合同是否有无期限
      noTermList: [
        { value: 0, name: '有期限' },
        { value: 1, name: '无期限' }
      ],
      // 堆场信息
      yardList: [
        { value: 1, name: '奥吉东靖堆场' },
        { value: 2, name: '奥吉江东' },
        { value: 4, name: '奥吉钢四堆场' },
        { value: 5, name: '奥吉华东堆场' }
      ],
      signorInfo: undefined,
      customerInfo: undefined,
      thirdCustomerInfo: undefined,
      fetching: false,
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: '系统编号',
          dataIndex: 'serial_num'
        },
        {
          title: '合同编号',
          dataIndex: 'contract_no'
        },
        {
          title: '合同文件',
          dataIndex: 'contract_file_list',
          scopedSlots: { customRender: 'contractFileSlot' }
        },
        {
          title: '合作方名称',
          customRender: (text, record) => {
            var allCustomerInfo = ''
            if (record.customer_info) {
              allCustomerInfo = allCustomerInfo + record.customer_info.customer_cn_name + '/' + record.customer_info.sap_code
            }
            if (record.third_customer_info) {
              allCustomerInfo = allCustomerInfo + ';'
              allCustomerInfo = allCustomerInfo + record.third_customer_info.customer_cn_name + '/' + record.customer_info.sap_code
            }
            return allCustomerInfo
          }
        },
        {
          title: '合同名称',
          dataIndex: 'contract_name'
        },
        {
          title: '邮件状态',
          dataIndex: 'send_email_flag',
          scopedSlots: { customRender: 'emailFlagSolt' }
        },
        {
          title: '业务类型',
          dataIndex: 'lease_type_name'
        },
        {
          title: '合同类型',
          dataIndex: 'contract_type_name'
        },
        {
          title: '付款类型',
          dataIndex: 'pay_type_name'
        },
        {
          title: '签署部门',
          dataIndex: 'signor_department'
        },
        {
          title: '签署人',
          dataIndex: 'signor_name'
        },
        {
          title: '签订日期',
          dataIndex: 'sign_date'
        },
        {
          title: '合同所属业务部门',
          dataIndex: 'department_str'
        },
        {
          title: '合同起始日期',
          dataIndex: 'contract_start_date',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '合同结束日期',
          dataIndex: 'contract_end_date',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '合同顺延截止日期',
          dataIndex: 'contract_extension_deadline'
        },
        {
          title: '合同终止日期',
          dataIndex: 'contract_termination_date',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '归档编号',
          dataIndex: 'filing_no'
        },
        {
          title: '结算方式',
          dataIndex: 'settlement_type'
        },
        {
          title: '合同信用期（天数）',
          dataIndex: 'credit_period'
        },
        {
          title: '合同标的额',
          dataIndex: 'contract_amount'
        },
        {
          title: '保证金/押金',
          dataIndex: 'deposit',
          customRender: (text) => {
            return text ? text === 1 ? '有' : '无' : null
          }
        },
        {
          title: '合同状态',
          dataIndex: 'contract_status_str'
        },
        {
          title: '税务类型',
          dataIndex: 'tax_type_str'
        },
        {
          title: '合作方税号',
          customRender: (text, record) => {
            var allCustomerTaxNo = ''
            if (record.customer_info && record.customer_info.tax_num) {
              allCustomerTaxNo = allCustomerTaxNo + record.customer_info.tax_num
            }
            if (record.third_customer_info && record.third_customer_info.tax_num) {
              allCustomerTaxNo = allCustomerTaxNo + ';'
              allCustomerTaxNo = allCustomerTaxNo + record.third_customer_info.tax_num
            }
            return allCustomerTaxNo
          }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '合同是否无期限',
          dataIndex: 'is_no_term',
          customRender: (text) => {
            return text ? text === 0 ? '有期限' : '无期限' : null
          }
        },
        {
          title: '堆场名称',
          dataIndex: 'yard_name'
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      visible: false,
      loading: false,
      title: null,
      form: {
        contract_no: null,
        enterprise_name: null,
        customer_id: null,
        lease_type: null,
        contract_type: null,
        pay_type: null,
        department_ids: [],
        signor_id: null,
        signor_name: null,
        signor_department: null,
        contract_start_date: null,
        contract_end_date: null,
        contract_extension_deadline: null,
        contract_termination_date: null,
        filing_no: null,
        contract_name: null,
        settlement_type: null,
        credit_period: null,
        contract_amount: null,
        deposit: null,
        contract_status: null,
        tax_type: [],
        remark: null,
        is_no_term: 0,
        yard_id: null
      },
      rules: {
        contract_no: [{ required: true, message: '请输入合同编号', trigger: 'blur' }],
        enterprise_name: [{ required: true, message: '请输入合作方名称', trigger: 'blur' }],
        customer_id: [{ required: true, message: '请选择客户信息', trigger: 'change' }],
        contract_type: [{ required: true, message: '请选择合同类型', trigger: 'change' }],
        pay_type: [{ required: true, message: '请选择付款类型', trigger: 'change' }],
        // department_ids: [{ required: true, message: '请选择业务部门', trigger: 'change' }],
        signor_id: [{ required: true, message: '请选择签署人', trigger: 'change' }],
        signor_department: [{ required: true, message: '请选择签署部门', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.loadData()
    initLeaseInfo().then(res => {
      this.leaseTypeOps = res.LEASE_TYPE_DATA
      this.payTypeOps = res.PAY_TYPE_DATA
      this.contractStatusOps = res.CONTRACT_STATUS_DATA
      this.taxTypeOps = res.TAX_TYPE_DATA
      this.taxTypeOpsNone = []
      this.taxTypeOps.forEach(element => {
        this.taxTypeOpsNone.push(element)
      });
      this.taxTypeOpsNone.push({
        name: '无',
        value: 0
      })
      this.contractTypeOps = res.CONTRACT_TYPE_DATA
      this.departmentOps = res.BUSINESS_DEPARTMENTS
    })
    getSysUserMetaOption('user').then((v) => {
      this.userOps = v
    })
  },
  methods: {
    changeSignorInfo(value) {
      if (value) {
        this.signorInfo = value
        this.form.signor_id = value.key
        this.form.signor_name = value.label
      } else {
        this.signorInfo = undefined
        this.form.signor_id = null
        this.form.signor_name = null
      }
    },
    beforeUpload(file) {
      const fileType = ['jpg', 'jpeg', 'png', 'doc', 'docx', 'pdf', 'xls', 'xlsx']
      const suffix = file.name.split('.')[1]
      if (fileType.indexOf(suffix) === -1) {
        this.$notification['error']({
          message: '提示',
          description: '只能上传word文件、pdf文件、excel文件、图片文件'
        })
        return false
      }
      if (file.size / 1024 / 1024 > 30) {
        this.$notification['error']({
          message: '提示',
          description: '上传文件不能超过30MB'
        })
        return false
      }
    },
    handleUpload(param, record) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('id', record.id)
      uploadFileByLeaseInfo(formData).then(res => {
        this.$notification['success']({
          message: '提示',
          description: '上传成功'
        })
        this.loadData()
      })
    },
    handleDeleteFile(record) {
      var contractNo = record.contract_no
      this.$confirm({
        title: '提示',
        content: '确定要删除' + contractNo + '的合同文件吗?',
        onOk: () => {
          deleteContractFile({ 'id': record.id }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除合同文件成功'
            })
            this.loadData()
          })
        }
      })
    },
    exportSheet() {
      this.$confirm({
        title: '提示',
        content: '确定要导出表格吗?',
        onOk: () => {
          exportLeaseInfo(this.queryParam).then(res => {
            if (res != null) {
              const fileInfo = { fileName: res }
              downloadExcel(fileInfo).then(downRes => {
                const url = window.URL.createObjectURL(
                  new Blob([downRes.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', res)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
      })
    },
    loadData() {
      pageLeaseInfo(this.queryParam).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['page_size'] = 10
      this.loadData()
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleCreate() {
      this.visible = true
      this.title = '新增合同信息'
    },
    handleUpdate(record, flag) {
      this.visible = true
      this.title = '修改合同信息'
      this.form = Object.assign({}, record)
      if (record.department_ids) {
        this.form.department_ids = record.department_ids
      }
      if (record.signor_id) {
        this.signorInfo = {
          key: record.signor_id,
          label: record.signor_name
        }
      }
      this.customerInfo = {
        key: record.customer_id,
        label: record.customer_info.customer_cn_name
      }
      if (record.third_customer_id) {
        this.thirdCustomerInfo = {
          key: record.third_customer_id,
          label: record.third_customer_info.customer_cn_name
        }
      }
      this.form.flag = flag // 此标识用于判断调用哪个修改接口
    },
    handleSubmit() {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          const reqParam = {
            ...this.form,
            'contract_start_date': this.form.contract_start_date ? moment(this.form.contract_start_date).valueOf() : null,
            'contract_end_date': this.form.contract_end_date ? moment(this.form.contract_end_date).valueOf() : null,
            'contract_termination_date': this.form.contract_termination_date ? moment(this.form.contract_termination_date).valueOf() : null,
            'sign_date': this.form.sign_date ? moment(this.form.sign_date).format('YYYY-MM-DD') : null
          }
          let fun
          if (this.form.flag === 'modifyRemark') {
            fun = modifyLeaseInfoRemark
          } else if (this.form.flag === 'modifyTaxType') {
            fun = modifyLeaseInfoTaxType
          } else {
            fun = saveOrUpdateLeaseInfo
          }
          fun(reqParam).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.handleCancel()
            this.loadData()
          }).catch(_ => {
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.customerInfo = undefined
      this.thirdCustomerInfo = undefined
      this.buyerOps = []
      this.signorInfo = undefined
      this.form = {
        contract_no: null,
        enterprise_name: null,
        customer_id: null,
        lease_type: null,
        contract_type: null,
        pay_type: null,
        department_ids: undefined,
        signor_id: null,
        signor_name: null,
        signor_department: null,
        contract_start_date: null,
        contract_end_date: null,
        contract_extension_deadline: null,
        contract_termination_date: null,
        filing_no: null,
        contract_name: null,
        settlement_type: null,
        credit_period: null,
        contract_amount: null,
        deposit: null,
        contract_status: null,
        tax_type: null,
        remark: null,
        is_no_term: 0,
        yard_id: null
      }
    },
    handleDelete() {
      this.$confirm({
        title: '提示',
        content: '确认要删除吗?',
        onOk: () => {
          const idList = this.selectedRows.map(item => item.id)
          deleteLeaseInfo({ ids: idList }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功'
            })
            this.loadData()
          })
        }
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.buyerOps = []
      this.fetching = true
      getCustomer({
        customerName: value,
        type: 'all'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.buyerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      if (value) {
        this.customerInfo = value
        this.form.customer_id = value.key
      } else {
        this.customerInfo = undefined
        this.form.customer_id = null
      }
      this.buyerOps = []
      this.fetching = false
    },
    changeThirdCustomerInfo(value) {
      if (value) {
        this.thirdCustomerInfo = value
        this.form.third_customer_id = value.key
      } else {
        this.thirdCustomerInfo = undefined
        this.form.third_customer_id = null
      }
      this.buyerOps = []
      this.fetching = false
    },
    signDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].format('YYYY-MM-DD')
        const endDate = date[1].format('YYYY-MM-DD')
        this.queryParam['sign_start_date'] = startDate
        this.queryParam['sign_end_date'] = endDate
      } else {
        this.queryParam['sign_start_date'] = null
        this.queryParam['sign_end_date'] = null
      }
    },
    validDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['valid_start_date'] = startDate
        this.queryParam['valid_end_date'] = endDate
      } else {
        this.queryParam['valid_start_date'] = null
        this.queryParam['valid_end_date'] = null
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>

<style scoped>

</style>
